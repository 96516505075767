
import template from './chart-view.html';

/**
 * Charts List VM
 */
class ChartViewPageViewModel
{
	constructor(page) {
		this.page = page;
		this.element = page.element;
		this.chart_options = ko.observableArray('');
		this.series_count = ko.observable('');
		this.dataset_count = ko.observable('');

		//chart id's
		this.chart_id = ko.observable('');
		this.data_source_id = ko.observable();
		this.legend_id = ko.observable(''); 
		this.tooltip_id = ko.observable('');
		this.x_axis_id = ko.observable('');
		this.y_axis_id = ko.observable('');

		//Chart params
		this.data_source_type = ko.observable();
		this.chart_name = ko.observable('');
		this.x_axis_name = ko.observable('');
		this.y_axis_name = ko.observable('');
		this.source_schema = ko.observable();
		this.source_table = ko.observable();
		this.data_source_report = ko.observable('');
		this.report_definition_id = ko.observable();
		this.report_definition_version = ko.observable();
		this.raw_data = ko.observableArray();
		
		this.series = ko.observableArray([{
			series_id: ko.observable(''),
			series_name: ko.observable(''),
			x_axis_id: ko.observable(''),
			y_axis_id: ko.observable(''),
			x_axis_field: ko.observable(''),
			y_axis_field: ko.observable(''),
			series_type: ko.observable('')
		}]);
		
		//Load Schemas and Tables
		this.available_schemas = ko.observableArray([]);
		this.available_columns = ko.observableArray([]);
		this.available_tables = ko.observableArray([]);
		this.available_types = ko.observableArray(["line", "bar"]);
		this.raw_table_data = ko.observableArray([]);
		
		this.data_source_type.subscribe(async () => {
			await this.get_schemas();
			await this.get_tables();
		});

		this.source_schema.subscribe(schema => {
			let tables = this.raw_table_data().filter(item => item.schema === schema).map(item => item.name);

			//let unique_tables = [...new Set(tables.map(item => item))];
			this.available_tables([...new Set(tables.map(item => item))]);
		});

		this.source_table.subscribe(async () => {
			await this.get_columns()
		});

		this.data_source_report.subscribe(async () => {
			await this.get_columns();
		});
	}

    async init_chart ()
    {
    	var myChart = echarts.init(document.getElementById('chart-graph'));
    	let chart_options = await Grape.fetches.getJSON('/api/echarts/chart/options',
    	{
            chart_id: this.chart_id()
    	});

		if(chart_options.legend.formatter)
			chart_options.legend.formatter = new Function('name', chart_options.legend.formatter);
		if (chart_options.tooltip.formatter)
			chart_options.tooltip.formatter = new Function('params', chart_options.tooltip.formatter);
		
		myChart.setOption(chart_options);
		this.chart_options(chart_options);

		if (this.chart_options() != '')
		{
			this.series_count(this.chart_options().series.length);
			//this.chart_type(this.chart_options().series[0].type);
			this.dataset_count(this.chart_options().dataset[0].source.length);
			this.x_axis_name(this.chart_options().xAxis[0].name);
			this.y_axis_name(this.chart_options().yAxis[0].name);
		}
    }

	async load ()	
	{
		if(this.chart_id() != '')
		{
			await this.get_chart_data();
			await this.init_chart();
		}
		else
			this.data_source_type('table');
	}

	async edit_chart ()
	{
		let response = await Grape.dialog.open('Chart', {
			chart_id: this.chart_id(), 
			x_axis: this.x_axis_name(), 
			y_axis: this.y_axis_name()
		});

		if (response)
			this.init_chart();
	}

	async get_chart_data ()
	{
		let data = await Grape.fetches.getJSON('/api/record', {
			schema: 'echarts',
			table: 'v_chart_info',
			filter: [
				{field: 'chart_id', operator: '=', value: this.chart_id()}
			]
		});

		if(data.records.length != 0)
		{
			let record = data.records[0];
			this.data_source_id(record.data_source[0].data_source_id)
			this.source_schema(record.data_source[0].data_source_options.source_schema);
			this.source_table(record.data_source[0].data_source_options.source_table);
			this.chart_name(record.name);			
			this.legend_id(record.legend_id);
			this.tooltip_id(record.tooltip_id);
			this.data_source_type(record.data_source[0].data_source_options.data_source_type);
			
			this.series([]);//empty series array before adding existing data
			for (let i = 0; i < record.series.length; i++)
			{
				let series = {
					series_id: record.series[i].series_id,
					series_name: record.series[i].series_name,
					x_axis_id: record.series[i].x_axis_id,
					y_axis_id: record.series[i].y_axis_id,
					x_axis_field: record.series[i].columns.source_field[0],
					y_axis_field: record.series[i].columns.source_field[1],
					series_type: record.series[i].series_type 
				}
				this.series.push(series);
			}

			if (this.data_source_type() === 'report')
			{
				this.report_definition_id(record.data_source[0].data_source_options.report.definition_id);
				this.report_definition_version(record.data_source[0].data_source_options.report.version);
			}
		}
		else
			Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'Unable to find chart data.'});
	}

	async get_schemas ()
	{
		if (this.data_source_type() === 'report')
		{	
			let data = await Grape.fetches.getJSON('/api/record', {schema: 'reports', table: 'v_latest_reports'});

			if (data.status === 'OK' && data.records.length != 0)
			{
				let reports_definitions = data.records.map(rec => {
					return {
						report_definition_id: rec.report_definition_id,
						version: rec.version,
						definition_name: rec.name,
						result_table: rec.result_table,
						params: rec.default_function_criteria
					}
				});
				this.raw_data(reports_definitions);
				this.available_schemas(reports_definitions);

				if (this.chart_id() != '')
				{
					let filter = this.available_schemas().filter(
						item => item.report_definition_id == this.report_definition_id()
					);
					this.data_source_report(filter[0]);
				}
			}
			else
				Grape.alerts.alert({type: 'warning', title: 'Warning', message: 'No data. Please ensure you have created a report to process.'});
		}
		else
		{
			let body = {
				schema: 'grape',
				table: 'v_pg_functions',
				filter: [
					{field: 'routine_schema', 'operator': '=', value: 'entity'},
					{field: 'routine_schema', 'operator': '=', value: 'data_export'},
					{field: 'routine_schema', 'operator': '=', value: 'crm'},
					{field: 'routine_schema', 'operator': '=', value: 'reports'}
				],
				filter_join: 'OR',
				limit: 5000
			}
			let response = await Grape.fetches.getJSON('/api/record', body);

			//let unique_schemas = [...new Set(response.records.map(item => item.routine_schema))];
			this.available_schemas([...new Set(response.records.map(item => item.routine_schema))]);
		}
	}

	async btnSave_click () {
		let data_source = await Grape.fetches.postJSON('/api/echarts/data/source', {
			data_source_id: this.data_source_id(),
			schema: this.data_source_type() === 'table' ? this.source_schema() : '',
			table: this.data_source_type() === 'table' ? this.source_table() : '',
			type: this.data_source_type(),
			definition_id: this.data_source_type() === 'table' ? '' : this.data_source_report().report_definition_id,
			version: this.data_source_type() === 'table' ? '' : this.data_source_report().version		
		});

		if (data_source.status === 'OK')
		{
			this.data_source_id(data_source.data_source_id);
			let chart = {
				chart_id: this.chart_id(),
				name: this.chart_name(),
				legend: {
					legend_id: this.legend_id(),
					type: 'scroll',
					formatter: `if (name=="${this.chart_name()}"){return ("Target (${this.chart_name()}) value")}`
				}
			};
			let series_data = this.series_builder();
			chart = {...chart, ...series_data};//ES6 Spread Operator

			let chart_data = await Grape.fetches.postJSON('/api/echarts/chart/save', {chart});
			if (chart_data.chart_id > 0)
			{
				//Grape.alerts.alert({type: 'success', title: 'Success', message: 'Chart Successfully saved'});
				this.load();
			}
		}
		else
			console.error(data_source);
	}
	//future def create legend builder and tooltip builder required series builder values
	
	series_builder ()
	{
	 	let series_data = ko.toJS(this.series());
		let result = {series: []};

		for (let i = 0; i < series_data.length; i++) {
			let series = series_data[i];
			let series_object;
			if (i != 0)
			{
				series_object = {
					series_id: series.series_id,
					name: series.series_name,
					data_source_id: this.data_source_id(),
					type: series.series_type,
					data_source_options: {
						source_field: [series.x_axis_field, series.y_axis_field]
					},
					series_type_options: {
						symbol: 'line', 
						line_style: {
							type: 'solid', 
							width: 3
						}
					},
					color: '#77dd77',
				};
			}
			else
			{
				series_object = {
					series_id: series.series_id,
					name: series.series_name,
					data_source_id: this.data_source_id(),
					type: series.series_type,
					data_source_options: {
						source_field: [series.x_axis_field, series.y_axis_field]
					},
					series_type_options: {
						symbol: 'line', 
						line_style: {
							type: 'solid', 
							width: 3
						}
					},
					x_axis: {
						axis_id: series.x_axis_id,
						type: 'category', 
						name: this.x_axis_name(), 
						name_location: 'middle', 
						name_gap: 50
					},
					y_axis: {
						axis_id: series.y_axis_id,
						type: 'value',
						name: this.y_axis_name(), 
						name_location: 'middle', 
						name_gap: 50
					},
					color: '#C62FB0'
				};
			};
			result.series.push(series_object);
		}
		return result;
	}

	async get_tables ()
	{
		let response = await Grape.fetches.getJSON('/api/record', {
			schema: 'grape',
			table: 'v_pg_tables',
			limit: 5000,
			fields: ['name', 'schema'],
			filter: [
				{field: 'schema', 'operator': '=', value: 'entity'},
				{field: 'schema', 'operator': '=', value: 'data_export'},
				{field: 'schema', 'operator': '=', value: 'crm'},
				{field: 'schema', 'operator': '=', value: 'reports'}
			],
			filter_join: 'OR'
		});
		this.raw_table_data(response.records);
	}

	async get_columns ()
	{
		if (this.data_source_type() != 'report')
		{
			let response = await Grape.fetches.getJSON('/api/record', {
				schema: 'grape',
				table: 'v_pg_columns',
				limit: 5000,
				filter: [
					{field: 'schema_name', 'operator': '=', value: this.source_schema()},
					{field: 'table_name', 'operator': '=', value: this.source_table()}
				],
				fields: ['column_name']
			});
			this.available_columns(response.records.map(item => item.column_name));
		}
		else
			this.available_columns(this.data_source_report().params.selected_columns);
	}
	
	btnAdd_series_click ()
	{
		this.series.push({ 
			series_id: ko.observable(''),
			series_name: ko.observable(''),
			x_axis_field: ko.observable(''),
			y_axis_field: ko.observable(''),
			series_type: ko.observable('')
		});
	}

	btnRemove_series_click ()
	{
		//Add observable to series that indicates delete series
	}
}

/**
 * page class
 */
class ChartViewPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ChartViewPageViewModel(this);
		this.name = 'ChartViewPageClass';
	}

	async init ()
	{
		this.viewModel.chart_id(this.bindings.chart_id);
		this.viewModel.chart_name(this.bindings.chart_name);
		//this.viewModel.x_axis_name(this.bindings.x_axis);
		//this.viewModel.y_axis_name(this.bindings.y_axis);

		await this.viewModel.load();
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]charts/chart-view/:chart_name/:chart_id',
	page_class: ChartViewPageClass,
	template: template
}
