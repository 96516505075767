import template from './chart-display.html';

/**
 * @kind component
 * @class ChartDisplayViewModel
 * @description Component for displaying a chart
 * @requirements  Chart_name or Chart_id [IF MORE THAN ONE CHART ON PAGE DEFINE CHART NUMBER as {chart_number: 1}], filter is also required
 */
class ChartDisplayViewModel
{
	constructor (params, element)
	{
		this.params = params;
		this.element = element;
		this.show_in_dialog = ko_helper.safe_observable(params.show_in_dialog || false);
		this.chart_size = ko_helper.safe_observable(params.chart_size || 'small');
		this.chart_name = ko_helper.safe_observable(params.chart_name);
		this.filters = ko_helper.safe_observable(params.filter);
		this.title = ko.observable('');
		this.chart = ko.observable();
		this.chart_options_loading = ko.observable(true);
		this.chart_options_not_loaded = ko.observable(false);
		this.chart_has_data = ko.observable(false);
		this.init();

		this.filters.subscribe(() => {
			if (typeof this.chart_name() != 'undefined')
				this.load_chart()
		});

		this.chart_name.subscribe(() => {
			if (typeof this.filters() != 'undefined')
				this.load_chart();
		});
	}

	async init ()
	{	
		if (typeof this.chart_name() != 'undefined')
			await this.load_chart();

		this.title(this.params.title() || '');
	}

	//TODO change this to something cleaner
	async get_var_value (variable)
	{
		// Create a temp element to apply the CSS var and get its computed value
		let temp_el = document.createElement('div');
		temp_el.style.cssText = `color: ${variable}; position: absolute; visibility: hidden;`;
		document.body.appendChild(temp_el);

		let computed_color = getComputedStyle(temp_el).color;
		document.body.removeChild(temp_el);

		return computed_color;
	}

	async load_chart ()
	{
		this.chart_options_loading(true);
		this.chart_has_data(false);

		try {
			let chartContainer;

			// Determine the correct chart container based on `show_in_dialog` and `chart_size`
			if (this.show_in_dialog())
				chartContainer = this.element.children[0].children[2]; // Dialog size
			else
			{
				switch (this.chart_size())
				{
					case 'small':
						chartContainer = this.element.children[0].children[1];
						break;
					case 'medium':
						chartContainer = this.element.children[0].children[2];
						break;
					case 'large':
						chartContainer = this.element.children[0].children[3];
						break;
					case 'extra-large':
					default:
						chartContainer = this.element.children[0].children[4];
						break;
				}
			}
	
			let my_chart = echarts.init(chartContainer);

			console.log('what is element?', chartContainer);

			let chart_options = await Grape.fetches.getJSON('/api/echarts/chart/options', { chart_name: this.chart_name(), data_source_options: this.filters() });

			if (chart_options.legend === undefined)
			{
				console.error('Chart options is undefined! Code: ', chart_options.code, ' Message: ', chart_options.message);
				this.chart_options_loading(false);
				this.chart_options_not_loaded(true);
				return;
			}

			if (chart_options.legend.textStyleId)
			{
				let text_style_response = await Grape.fetches.getJSON('/api/echarts/chart/text/style', { text_style_id: chart_options.legend.textStyleId });

				let var_color = text_style_response.color || '#fff';
				let text_color = var_color.startsWith('var') ? await this.get_var_value(var_color) : var_color;

				chart_options.legend.textStyle = {
					color: text_color
				};

				chart_options.xAxis.forEach(axis => {
					axis.axisLabel = { color: text_color };
					axis.nameTextStyle = { color: text_color };
				});

				chart_options.yAxis.forEach(axis => {
					axis.axisLabel = {
						color: text_color,
						formatter: (value) => {
							if (axis.name === "Value")
								return 'R ' + value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
							return value;
						}
					};
					axis.nameTextStyle = { color: text_color };
				});

				chart_options.grid = {
					containLabel: true,
				};
			}

			if (chart_options.legend.formatter)
				chart_options.legend.formatter = new Function('name', chart_options.legend.formatter);
			if (chart_options.tooltip.formatter)
				chart_options.tooltip.formatter = new Function('params', `
					if (!params.length) params = [params];
					let seriesInfo = params.map((x) => {
						let value = x.data && x.data[1] !== undefined ? (x.seriesName === "Value" ? 'R ' + x.data[1].toLocaleString('en-ZA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : x.data[1]) : "N/A";
						return x.marker + " " + x.seriesName + " : " + value + "<br>";
					});
					return seriesInfo.join("");
				`);
	
			if (chart_options.dataset)
			{
				chart_options.dataset.forEach((dataset, index) => {
					if (dataset.source && dataset.source.length > 0)
						this.chart_has_data(true);
				});
			}

			if (this.chart_has_data() === true)
				my_chart.setOption(chart_options);
			else
				my_chart.clear();

		} catch (error) {
			console.error(error);
			this.chart_options_not_loaded(true);
		}

		this.chart_options_loading(false);
	}
}

export default {
	name: 'chart-display',
	viewModel: ChartDisplayViewModel,
	module_type: 'ko',
	template: template
};
