
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';

import package_json from '../package.json';

(function() {	
	window.Grape.modules.push(package_json);

	window.Grape.dialog.registerDialogs(dialogs);
	window.Grape.pages.registerPages(pages);
	window.Grape.component.registerComponents(components);

	Grape.registry.addItem(
		'PSServerDashboardPages',
		`${pages[0].title}`,
		{
			title: pages[0].title,
			icon: pages[0].icon,
			page_id: pages[0].page_id || pages[0].route || null,
			idx: pages[0].idx
		}
	);
})();

